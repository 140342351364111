@font-face {
    font-family: 'ChameleonCollective';
    src: url('../../assets/fonts/ChameleonCollective-Regular.otf'),
        url('../../assets/fonts/ChameleonCollective-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


.chama_nft_card_wrapper {
    width: 96%;
    border: 2px solid #7031BD;
    border-radius: 8px;
}

.chama_nft_card_wrapper_heading {
    width: 100%;
    height: 90px;
    background-color: #7031BD;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

}

.chama_down_icon {
    color: #fff;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #37185c;
}

.nft_card_heading {
    font-family: 'ChameleonCollective';
    color: #FFF;
    font-size: 40px;
    cursor: pointer;
}

.nft-show-more-card {
    width: 120px;
    height: 40px;
    margin: 12px 0;
}

.btn__info {
    animation: blink 2s linear infinite;
}
@keyframes blink{
    0%{opacity: 0;}
    50%{opacity: .5;}
    100%{opacity: 1;}
}

.create-new {
    margin-top: 25px;
    margin-bottom: 20px;
    width: max-content;
    background-color: #2abaf5;
    height: 40px;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
}

@media (max-width: 420px) {
    .nft_card_heading {
        font-size: 32px;
    }   
}

@media (max-width: 940px) {
    .nft-show-more-card {
        display: flex !important;
    }
}
