@font-face {
    font-family: 'Bebas';
    src: url('../../assets/fonts/Bebas-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Bebas-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.footer {
    background-color: #7b0083;
    padding: 20px 65px;
    padding-bottom: 80px;
}

.footer_icon {
    width: 50px;
}

a {
    margin-right: 20px;
}

.footer_text {
    text-align: left;
    color: #fff;
    font-family: 'Bebas';
    font-size: 30px;
    text-transform: uppercase;
}

.footer_text > span {
    margin-right: 10px;
}

@media (max-width: 999px) {
    .footer {
        padding: 20px;
        padding-bottom: 80px;
    }

    .footer_icon {
        width: 40px;
    }

    .footer_text {
        font-size: 24px;
    }

}

@media (max-width: 767px) {
    .footer_icon {
        width: 30px;
    }

    .footer_text {
        font-size: 22px;
    }

}

@media (max-width: 420px) {
    .footer {
        padding: 0px;
        padding-bottom: 80px;
    }

    .footer {
        padding: 20px;
        padding-bottom: 60px;
    }

    .footer_text {
        margin-bottom: 20px;
    }

}