

.nft_card_wrapper {
    width: 96%;
    border: 2px solid #01BFD9;
    border-radius: 8px;
}

.nft_card_wrapper_heading {
    width: 100%;
    height: 90px;
    background-color: #01BFD9;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

}

.nft_card_wrapper_heading > img {
    height: 100%;
}

.down_icon {
    color: #fff;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #016674;
}