.potions_nft_2_card_wrapper {
    width: 96%;
    border: 2px solid #7031BD;
    border-radius: 8px;
}

.potions_nft_2_card_wrapper_heading {
    width: 100%;
    height: 90px;
    background-color: #7031BD;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

}

.potions_nft_2_card_wrapper_heading > img {
    height: 100%;
}

.down_icon_2 {
    color: #fff;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #37185c;
}

.potions_nft_2_card {
    width: 15.66%;
    margin: 12px .5%;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
}

.custom-checkbox {
    border: 1px solid #7031BD;
    width: 22px;
    height: 22px;
}

.potions_nft_2_card > img {
    max-width: 100%;
}

.potion-mint-modal-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    z-index: 1000;
}

.potion_after_mint_nftImg > img {
    width: 420px;
    height: 420px;
}

.overlay-potion-img {
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 100;
    border-radius: 6px;
}

.overlay-potion-img-toggler {
    bottom: -30px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 101;
}

.compose-cham-from-api {
    width: 420px;
    height: 420px;
    border-radius: 6px;
}

.compose-cham-from-api > img {
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 6px;
}

.compose-cham-from-api-alt {
    width: 300px;
    height: 300px;
    border-radius: 6px;
}

.compose-cham-from-api-alt > img {
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 6px;
}

.compose-cham-from-api-Overlay {
    z-index: 50;
}

.compose-cham-from-api-Background {
    z-index: 10;
}

.compose-cham-from-api-Eye {
    z-index: 35;
}

.compose-cham-from-api-Mouth {
    z-index: 30;
}

.compose-cham-from-api-Clothes {
    z-index: 40;
}

.compose-cham-from-api-Hat {
    z-index: 20;
}

.compose-cham-from-api-Body {
    z-index: 25;
}

.compose-cham-from-api-Prop {
    z-index: 45;
}

.compose-cham-from-api-Tail {
    z-index: 15;
}

.potion-img-display {
    width: 25px;
    height: 25px;
}

@media (max-width: 940px) {
    .potions_nft_2_card {
        width: 19%;
    }   
}

@media (max-width: 800px) {
    .potions_nft_2_card {
        width: 24%;
    }   
}

@media (max-width: 600px) {
    .potions_nft_2_card {
        width: 32%;
    }   
}

@media (max-width: 420px) {
    .potions_nft_2_card {
        width: 48%;
        margin: 1%;
    }   
}