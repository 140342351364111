@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap');

@font-face {
    font-family: 'ChameleonCollective';
    src: url('../../assets/fonts/ChameleonCollective-Regular.otf'),
        url('../../assets/fonts/ChameleonCollective-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas';
    src: url('../../assets/fonts/Bebas-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Bebas-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.create-cham-heading {
    font-family: 'ChameleonCollective';
    font-size: 60px;
    color: #37185c;
    word-spacing: 20px;
}

.create-cham-subheading {
    font-family: 'Bebas';
    font-size: 32px;
    color: #019a88;
}

.create-cham-preview {
    border: 1px solid #707070;
    border-radius: 5px;
}

.create-cham-trait-thumbnail {
    border: 1px solid #c0c0c0;
    border-radius: 5px;
}

.create-cham-trait-thumbnail-name {
    font-family: 'Oswald', sans-serif;
    white-space: nowrap; 
    width: 90px; 
    overflow: hidden;
    text-overflow: ellipsis; 
}

.create-cham-preview-text {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: 'ChameleonCollective';
    color: #37185c;
}

.create-cham-preview-dyn-img {
    top: 0;
}

.create-cham-preview-bg {
    z-index: -1;
}

.create-cham-preview-Overlay {
    z-index: 50;
}

.create-cham-preview-Background {
    z-index: 10;
}

.create-cham-preview-Eye {
    z-index: 35;
}

.create-cham-preview-Mouth {
    z-index: 30;
}

.create-cham-preview-Clothes {
    z-index: 40;
}

.create-cham-preview-Hat {
    z-index: 20;
}

.create-cham-preview-Body {
    z-index: 25;
}

.create-cham-preview-Prop {
    z-index: 45;
}

.create-cham-preview-Tail {
    z-index: 15;
}

.create-cham-preview-Robotic {
    z-index: 60;
}

.create-cham-preview-Pygmy {
    z-index: 65;
}

.create-cham-preview-Mutant {
    z-index: 55;
}

.create-cham-preview-Border {
    z-index: 70;
}

.create-cham-traits {
    max-height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
}