@font-face {
    font-family: 'Bebas';
    src: url('../../assets/fonts/Bebas-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Bebas-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.card {
    width: 48%;
    margin: 20px;
    border-width: 20px;
    border-bottom-width: 80px;
    border-style: solid;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 24px
}

.Cosmic {
 border-color: #7031bd;
}

.Woodland {
 border-color: #019a88;
}

.Underworld {
 border-color: #ff4401;
}

.Oceanic {
 border-color: #0196fa;
}

.card_title {
    bottom: -60px;
    left: 14px;
    color: #fff;
    font-size: 30px;
    font-family: 'Bebas';
    letter-spacing: 1px;
}

@media (max-width: 767px) {
    .card {
        width: 48%;
        margin: 20px;
        border-width: 10px;
        border-bottom-width: 40px;
        border-top-left-radius: 12px;
        border-bottom-right-radius: 18px
    }

    .card_title {
        bottom: -36px;
        left: 0;
        font-size: 24px;
        letter-spacing: 1px;
    }
}