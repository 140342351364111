
.leaderboard_component {
    width: 96%;
    min-height: 50px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 50%);
    border-radius: 4px;
}

.leaderboard_medal {
    border-left: 2px solid grey;
    width: 48px;
    height: 40px;
}

.leaderboard_medal > div {
    font-size: 12px;
    margin-left: 8px;
}

.leaderboard_nftImg > img {
    width: 40px;
    height: 40px;
    border-radius: 4px;
}

.leaderboard_rank {
    padding: 4px 12px;
    font-weight: 500;
    border: .13rem solid #707070;
    border-radius: 4px;
}

.leaderboard_account_Code {
    width: 400px;
    margin: 0 12px;
    overflow-x: hidden;
    text-overflow: ellipsis; 
}

.leaderboard_account_Code:hover {
    text-decoration: underline;
    color: dodgerblue;
}

@media (max-width: 700px) {
    .leaderboard_account_Code {
        width: 300px;
    }
}

@media (max-width: 460px) {
    .leaderboard_account_Code {
        width: 120px;
        margin: 0 8px;
    }
    .leaderboard_rank {
        padding: 2px 8px;
        font-weight: 400;
    }
    .leaderboard_medal {
        width: 40px;
        height: 40px;
    }
}