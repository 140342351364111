
.icons_header {
    background-color: #e8e8e8; 
    padding: 16px 40px; 
    font-family: 'Bebas'; 
    font-size: 24px; 
    color: #4f4f4f; 
    overflow-x: auto;
    white-space: nowrap;
}

.icons_header > div > img { 
    width: 36px;
    max-height: 40px;
}

.heder_money_icon {
    width: 24px !important;
}