

.content_wrapper {
    width: 100%;
    min-height: 80vh;

}

.top_img_sec {
    width: 100%;
    height: 150px;
    box-shadow: inset 2px 2px 40px #555;
}

.top_img_sec > img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    background-position: center;
    /* background-color: rgba(123, 0, 131, .85) !important; */
}

.edit_bg_pointer {
    width: 40px;
    height: 40px;
    top: 55px;
    right: 55px;
    border-radius: 4px;
    color: #999;
}

.edit_bg_pointer > label > svg {
    width: 100%;
    cursor: pointer;
}

.loaderDivBg {
    z-index: 1000;
    top: 66%;
    right: 45px;
}