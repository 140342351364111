@font-face {
    font-family: 'Bebas';
    src: url('../../assets/fonts/Bebas-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Bebas-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.factions{
    background-color: #fff;
    color: #000;
}

.faction_heading {
    padding: 64px 60px 30px 60px;
    text-align: left;
}

.faction_heading > h1 {
    font-family: 'Bebas';
    max-width: max-content;
    padding-right: 14px;
    border-bottom: 8px solid #ff9201;
}

.faction_heading > p {
    font-weight: 500;
    line-height: 1.5;
    font-size: 22px;
}

.faction_content {
    text-align: left;
    padding: 0px 60px 0px 24px;
    font-weight: 400;
    font-size: 18px;
}

.text_highlight {
    font-family: 'Bebas';
    font-size: 32px;
    text-transform: uppercase;
    color: #7031bd;
}

@media (max-width: 768px) {
    .factions{

    }
    .faction_heading {
        padding: 24px;
    }   
    .faction_heading > p {
        font-size: 20px;        
        line-height: initial;
    }   
}