@font-face {
    font-family: 'Bebas';
    src: url('../../../assets/fonts/Bebas-Regular.woff2') format('woff2'),
        url('../../../assets/fonts/Bebas-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.side_navbar {
    width: 250px;
    min-height: 100vh;
    background-color: #c3dd05;
    font-family: 'Bebas';
    font-size: 18px;
    letter-spacing: 2px;
    text-align: center;
    z-index: 10;
}

li {
    list-style: none;
    padding: 12px;
}

a {
    text-decoration: none;
    color: #000;
}

a:hover {
    color: #fff;
}

.navClose {
    cursor: pointer;
}