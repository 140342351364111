@font-face {
    font-family: 'ChameleonCollective';
    src: url('../../assets/fonts/ChameleonCollective-Regular.otf'),
        url('../../assets/fonts/ChameleonCollective-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


.leaderboard {
    height: 75vh;
    width: 940px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 50%);
    border-radius: 4px;
    overflow-x: scroll;
}

.leaderboard_header {
    position: sticky;
    top: 0;
    min-height: 80px;
    text-align: center;
    font-family: 'ChameleonCollective';
    background-color: #01BFD9;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #fff;
    font-size: 30px;
}


@media (max-width: 966px) {
    .leaderboard {
        width: 98%;
    }
    .leaderboard_header {
        min-height: 60px;
        font-size: 22px;
    }
}

@media (max-width: 700px) {
    .leaderboard_header {
        min-height: 60px;
        font-size: 22px;
    }
}

@media (max-width: 460px) {
    .leaderboard_header {
        min-height: 60px;
        font-size: 18px;
    }
}
