
.nft_card {
    transition: 300ms;
}

.nft_card:hover {
    transform: scale(1.08);
}

.clock {
    width: 60%;
    box-shadow: .8px .8px 0 1.2px #606060 inset;
    border-radius: 1px;
}

.time { 
    font-size: 20px;
    color: #303030;
    margin: 4px 2px;
    letter-spacing: 1px;
}

.feed_btn {
    background: #cbf7de;
    border: 5px solid #afe4c6;
    border-radius: 24px;
    box-shadow: 3px 5px 0 2px #e0e0e0;
    margin: 0 16px 8px 16px;
    padding: 8px 12px;
    font-family: 'Bebas';     
    font-size: 22px;
    letter-spacing: .5px;
    font-weight: 200;
    cursor: pointer;
}

.feed_btn_disable {
    background: #d0d0d0;
    border: 5px solid #b0b0b0;
    border-radius: 24px;
    box-shadow: 3px 5px 0 2px #e0e0e0;
    margin: 0 16px 8px 16px;
    padding: 8px 12px;
    font-family: 'Bebas';     
    font-size: 22px;
    letter-spacing: .5px;
    font-weight: 200;
    cursor: pointer;
}

.feed_btn {
    transition: 300ms;
}

.feed_btn:hover {
    transform: scale(1.08);
}

.edit_chamagotchi {
    top: 10px;
    left: 12px;
    transition: 300ms;
}

.edit_chamagotchi:hover {
    transform: scale(1.2);
}

.chamagotchi_more {
    top: 15px;
    right: 15px;
    padding: .75px 4px ;
    border-radius: 4px;
    border: 1px solid #01BFD9;
    color: #01BFD9;
    transition: 300ms;
}

.chamagotchi_more:hover {
    background-color: #01BFD9;
    color: #ffffff;
}

.chamagotchi_more_options {
    top: 50px;
    right: 15px;
    color: #7031BD;
    border: 1px solid #7031BD;
    border-radius: 4px;
    background-color: rgba(218, 218, 218, .75);
}

@media (max-width: 1050px) {
    .feed_btn, .feed_btn_disable{
        padding: 4px 12px;
    }
}
