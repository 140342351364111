

.pet_Assembly_wrapper {
    width: 100%;
    position: relative;
    /* padding: 30px; */
}

.placeholder_chamagotchi {
    z-index: -1000;
    padding: 30px;
}

.pet {
    width: 100%;
    padding: 30px;
    position: absolute;
    top: 0;
    left: 0;
}

/* z-index table */

.character {
    z-index: 70;
}

.screen_color  {
    z-index: 60;
}

.screen_frame, .buttons, .chain {
    z-index: 50;
}

.bottom_frame {
    z-index: 40;
}

.top_frame {
    z-index: 30;
}

.horn_tail {
    z-index: 20;
}

.background {
    z-index: 10;
}