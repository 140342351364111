
.all-cham-slider {
    max-height: 600px;
    overflow-y: scroll;
}

.all-cham-slider::-webkit-scrollbar{
    width: 4px;
    height: auto;
}

@-moz-document url-prefix(){
    /* width */
    .all-cham-slider::-webkit-scrollbar{
      width: 10px;
      height: auto;
    }
}

.apply-inventory-btn {
    top: 8px;
    right: 10px;
}