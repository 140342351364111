

.thumbnanil {
    width: 18%;
    height: 100%;
    margin: 8px 1%;
}

.thumbnanil {
    transition: 300ms;
}

.thumbnanil:hover {
    transform: scale(1.2);
}

.Selected_thumbnail {
    border: 6px solid #86c9cc;
    border-radius: 10px;
    position: relative;
}

.Selected_thumbnail::before {
    content: '';
    background-image: url(../../assets/Img/checkmark.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 20px;
    top: 0;
    left: 0;
    z-index: 100;
}

@media (max-width: 768px) {
    .Selected_thumbnail {
        border: 4px solid #86c9cc;
        border-radius: 5px;
        position: relative;
    }
}

@media ( max-width: 576px ) {
    .thumbnanil {
        width: 31%;
        height: 100%;
        margin: 8px 1.156%;
    } 
}