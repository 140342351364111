@font-face {
    font-family: 'Bebas';
    src: url('../../assets/fonts/Bebas-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Bebas-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.modal_content{
    background: black;
    color: #fff;
    border-radius: 2px;
}

.modal_title {
    color: #c8df00;
    font-family: 'Bebas';
    letter-spacing: 3px;
    font-size: 35px;
}

.modal_header > h6 {
    font-size: 20px;
}

.close_btn {
    top: 10px;
    right: 10px;
    outline: none;
    border: none;
    background: #000;
    padding: 5px 7px;
    border-radius: 2px;
}

.close_btn:hover {
    background: red;
}

.modal_body {
    font-size: 18px;
    text-align: left;
    font-weight: 500;
}

.modal_body > ul {
    padding: 25px 32px;
}

.modal_body > ul > li {
    padding: 4px;
    padding-left: 24px;
    background-image: url("../../assets/Img//li_icon.png");
    background-position: left;
    background-repeat: no-repeat;
    background-size: 14px;
} 

