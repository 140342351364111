
.profile {
    margin-bottom: 30px;
}

.profile_img {
    width: 150px;
    height: 150px;
    background-color: #01BFD9;
    border: 2px solid #fff;
    border-radius: 150px;
    top: -75px;
    left: calc(50% - 75px);
    margin-bottom: 26px;
}

.profile_img > img {
    width: 100%;
    height: 100%;
    border-radius: 150px;
}

.userName {
    margin-top: 85px;
    font-size: 30px;
    margin-bottom: 16px;
}

.sub_text {
    font-size: 20px;
}

.mini_social_icon {
    width: 100px;
    margin: 24px auto;
}

.mini_social_icon > img {
    cursor: pointer;
}

.twitter, .instagram {
    width: 40px;
    height: 40px;
    background-color: #383838;
    padding: 8px;
    border-radius: 8px;
}

.email {
    margin-bottom: 70px;
    font-size: 26px;
    font-weight: 300;
}

.eth_add {
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: 400;
}

.edit_profile_btn {
    margin-top: 50px;
    margin-bottom: 40px;
    background-color: #7B0083;
    width: 324px;
    height: 80px;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 28px;
    font-weight: 700;
    color: #fff;
}

.edit_profile_btn:focus {
    outline: none;
}


@media (max-width: 660px) {
    .eth_add {
        margin-bottom: 6px;
        font-size: 20px;
        font-weight: 400;
        width: 600px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (max-width: 420px) {
    .eth_add {
        margin-bottom: 2px;
        font-size: 16px;
        font-weight: 300;
        width: 350px;
    }
}