.navbar {
    width: 100%;
    background-color: #c3dd05;
    z-index: 9999;
    padding: 4px 0;
}

.navbar > div {
    padding: 4px 40px;
}

.nav_logo {
    width: 185px;
    cursor: pointer;
}

.nav_logo > img {
    width: 100%;
}

.nav_icons {
    margin-right: 16px;
    cursor: pointer;
}

.nav_icons > img {
    width: 27px;
}

.hamburger_menu {
    cursor: pointer;
}

.side_nav {
    top: 0;
    right: 0;
}

.profile_icon {
    width: 36px;
    height: 36px;
    background-color: #01BFD9;
    border-radius:  36px;
}

.profile_icon > img {
    width: 36px;
    height: 36px;
    border: 1px solid #fff;
    border-radius:  36px;
    cursor: pointer;
}

.profile_icon_dropdown {
    right: 0;
    width: 180px;
    padding: 6px;
    /* outline: 4px solid  rgba(123, 0, 131, .7);
    outline-offset: 3px; */
    border: 2px solid  #01BFD9;
}

.profile_icon_dropdown_icon {
    cursor: pointer;
    color: #555;
}

.profile_icon_dropdown_icon > svg {
    width: 20px;
    margin: 0 8px;
}

.dropdown_svg_icon {
    width: 20px;
    height: 20px;
    margin: 0 8px;
}

.nav_link {
    font-size: 18px;
    text-decoration: none;
    /* text-transform: uppercase; */
}

.nav_link:hover {
    color: #01BFD9;
    text-decoration: none;
    /* padding: 4px 8px; */
}

.active_nav {
    border-bottom: 2px solid #01BFD9;
}

.dropdown_hr{
    width: 96%;
    height: 1px !important;
    color: #01BFD9;
}

/* @media (max-width: 768px) {
    .navbar > div {
        padding: 10px;
    }
} */

@media (max-width: 420px) {
    .navbar > div {
        padding: 0;
    }
}