
.checkmark {
    width: 40px;
    position: absolute;
    top: -12px;
    right: 50px;
    z-index: 100;
}

.design_menu > div {
    transition: 300ms;
}

.design_menu > div:hover {
    transform: scale(1.08);
}

@media ( max-width: 1200px ) {
    .checkmark {
        right: 30px;
    }
}

@media ( max-width: 768px ) {
    .checkmark {
        width: 30px;
        top: -8px;
        right: 0px;
    }
}

@media ( max-width: 576px ) {
    .checkmark {
        width: 20px;
        top: -4px;
    }
}

@media ( max-width: 480px ) {
    .checkmark {
        right: -30px;
    }
}

@media ( max-width: 420px ) {
    .checkmark {
        width: 16px;
        right: -10px;
    }
}