.cham-dressing-page {
    max-height: calc(100vh - 65px);
    background-color: #000;
    /* overflow-y: scroll; */
}

.all-cham-slider-wrapper {
    background-color: #fff;
    /* border-radius: 4px; */
}

.all-cham-slider-alt {
    max-height: calc(100vh - 144.5px);
    overflow-y: scroll;
}

.all-cham-slider-alt::-webkit-scrollbar{
    width: 4px;
    height: auto;
}

@-moz-document url-prefix(){
  
    /* width */
    .all-cham-slider-alt::-webkit-scrollbar{
      width: 10px;
      height: auto;
    }
}

.cham-overlay-preview-wrapper {
    max-height: calc(100vh - 65px);
    overflow-x: hidden;
    height: 100%;
    padding-right: 2px;
}

.cham-overlay-preview-wrapper-container {
    overflow-y: scroll;
    overflow-x: hidden;
}

.cham-overlay-preview-wrapper-container::-webkit-scrollbar{
    width: 4px;
    height: auto;
}

@-moz-document url-prefix(){
    /* width */
    .cham-overlay-preview-wrapper-container::-webkit-scrollbar{
      width: 4px;
      height: auto;
    }
}

.cham-overlay-preview-wrapper > div, .overlayed-cham-wishlist {
    color: #000;
    background-color: #fff;
}

.header-btn-styles {
    /* border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important; */
}

.overlay-custom-accordin-wrapper {
    background: #FFF !important;
    margin: 0 2.4px;
    /* border-left: 2px solid #333 !important; */
    /* border-right: 2px solid #333 !important; */
    max-height: calc(100vh - 62px);
}

.overlay-custom-accordin-container {
    max-height: calc(100vh - 104px);
    overflow-y: scroll;
}

.overlay-custom-accordin-container::-webkit-scrollbar{
    width: 4px;
    height: auto;
}

@-moz-document url-prefix(){
    /* width */
    .overlay-custom-accordin-container::-webkit-scrollbar{
      width: 4px;
      height: auto;
    }
}

.overlay-custom-accordin-wrapper .accordion .accordion-item .accordion-header {
    background: rgb(214,214,214) !important;
    background: linear-gradient(180deg, rgba(214,214,214,1) 0%, rgba(244,244,244,1) 25%, rgba(255,255,255,1) 100%) !important;
}

.overlay-thumbnails-wrapper {
    max-height: 333px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.overlay-thumbnails-wrapper::-webkit-scrollbar{
    width: 4px;
    height: auto;
}
  
@-moz-document url-prefix(){
    /* width */
    .overlay-thumbnails-wrapper::-webkit-scrollbar{
        width: 4px;
        height: auto;
    }
}
  
.overlay-thumbnails {
    border: 1px solid #a4a4a4;
    border-radius: 4px;
}

.overlay-thumbnails-check-box {
    top: 0;
    left: 8px;
}

.overlay-thumbnails-check-box > input {
    width: 16px;
    height: 16px;
}

.cham-thumbnails-check-box {
    top: 0;
    left: 10px;
}

.cham-thumbnails-check-box > input {
    width: 16px;
    height: 16px;
}

.overlayed-cham-wishlist-compare-btn {
    bottom: 0px;
    background-color: #fff;
    z-index: 14;
}

.wishlist-container {
    max-height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 100px !important;
}

.wishlist-container-times {
    top: -12px;
    right: -10px;
    z-index: 14;
    background-color: #c52a9b;
    width: 30px;
    height: 30px;
    border-radius: 30px;
}

.overlay-custom-accordin-wrapper .accordion .accordion-item .accordion-header .accordion-button {
    padding: 10px !important;
}

.overlay-custom-accordin-wrapper .accordion .accordion-item .accordion-collapse .accordion-body {
    padding: 16px !important;
}

.csaiw-wrappper {
    top: 744px;
    border-radius: 4px;
    height: 96px;
    background-color: #FFF;
    margin: auto;
}

.cham-slider-add-img-wrapper {
    max-width: 96px;
    margin: auto;
    transition: 300ms;
}

.cham-slider-add-img-wrapper:hover {
    transform: scale(.95);
}

.cham-slider-add-img .tooltiptext {
    visibility: hidden;
    width: 100px;
    background-color: #c3dd05;
    color: #000;
    text-align: center;
    font-size: 12px;
    border-radius: 4px;
    padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    top: 20px;
    right: 24%;
    z-index: 101;
}
  
.cham-slider-add-img:hover .tooltiptext {
    visibility: visible;
}

.dressing-preview-zoom-popup {
    background-color: #fff;
    max-width: 440px;
}

.overlay-thumbnails-price {
    font-size: 14px;
}

.dressing-preview-zoom-popup-close{
    top: -32px;
    right: -32px;
    z-index: 100;
    background: red;
    border-radius: 24px;
}

.cham-thumbnails-token-no {
    top: 0;
    right: 6px;
    z-index: 100;
}