@font-face {
    font-family: 'Bebas';
    src: url('../../assets/fonts/Bebas-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Bebas-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.founder_sec {
    background-color: #fff;
    padding: 45px;
}

.founder > h2 {
    font-family: 'Bebas';
    font-size: 52px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #ff9200;
}

.founder > h3 {
    font-family: 'Bebas';
    font-size: 30px;
    text-transform: uppercase;
}

.founder > p {
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    color: #000;
    padding: 0 10px;
}

@media (max-width: 767px) {
    .founder > p {
        font-size: 20px;
        font-weight: 400;
        padding: 10px 32px;
        margin-bottom: 12px;
    }
}