
.burned_popup_wrapper {
    position: absolute;
    top: -200px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, .8);
    display: flex;
    justify-content: center;
    z-index: 1010;
}

.burned_popup_container {
    top: calc(50% - 220px);
    position: fixed;
    width: 750px;
    height: max-content;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    z-index: 1011;
}

.burned_close_popup {
    border: none;
    outline: none;
    width: 20px;
    height: 20px;
    display: flex;
    margin-left: auto;
    padding: 0;
    color: #444;
}
