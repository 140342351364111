.potions_nft_card_wrapper {
    width: 96%;
    border: 2px solid #01BFD9;
    border-radius: 8px;
}

.potions_nft_card_wrapper_heading {
    width: 100%;
    height: 90px;
    background-color: #01BFD9;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

}

.potions_nft_card_wrapper_heading > img {
    height: 100%;
}

.down_icon {
    color: #fff;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #016674;
}

.potions_nft_card {
    width: 15.66%;
    margin: 12px .5%;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
}


.custom-checkbox {
    border: 1px solid #01BFD9;
    width: 22px;
    height: 22px;
}

.potions_nft_card > img {
    max-width: 100%;
}

.potion-mint-modal-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
}

@media (max-width: 940px) {
    .potions_nft_card {
        width: 19%;
    }   
}

@media (max-width: 800px) {
    .potions_nft_card {
        width: 24%;
    }   
}

@media (max-width: 600px) {
    .potions_nft_card {
        width: 32%;
    }   
}

@media (max-width: 420px) {
    .potions_nft_card {
        width: 48%;
        margin: 1%;
    }   
}