
.menu_btn {
    background-image: url("../../../assets/Img/purple-button.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 4px auto;
    padding: 12px;
    width: 240px;
    min-height: 64px;
    font-family: 'Bebas';     
    font-size: 22px;
    letter-spacing: .5px;
    color: #7595c6;
    font-weight: 200;
    cursor: pointer;
}

.active_menu_btn {
    background-image: url("../../../assets/Img/Rectangle4.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 4px auto;
    padding: 18px;
    width: 240px;
    min-height: 72px;
    font-family: 'Bebas';     
    font-size: 22px;
    letter-spacing: .5px;
    color: #7595c6;
    font-weight: 200;
    cursor: pointer;
}

@media (max-width: 768px) {
    .menu_btn {
        padding: 8px 18px;
        width: 190px;
        min-height: 50px;
        font-size: 18px;
    }
    
    .active_menu_btn {
        padding: 14px 18px;
        width: 190px;
        min-height: 58px;
        font-size: 18px;
    }
}

@media ( max-width: 576px ) {
    .menu_btn {
        padding: 5px 10px;
        width: 150px;
        min-height: 40px;
        font-size: 16px;
    }
    
    .active_menu_btn {
        padding: 8.5px 10px;
        width: 150px;
        min-height: 46px;
        font-size: 16px;
    }
}

@media ( max-width: 420px ) {
    .menu_btn {
        padding: 2px 10px;
        width: 100px;
        min-height: 26px;
        font-size: 14px;
    }
    
    .active_menu_btn {
        padding: 4px 10px;
        width: 100px;
        min-height: 30px;
        font-size: 14px;
    }
}