@font-face {
    font-family: 'Bebas';
    src: url('../../assets/fonts/Bebas-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Bebas-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.feed_banner_title {
    font-family: 'Bebas'; 
    font-size: 40px; 
    margin-bottom: 0;
    color: #fff;
    position: relative;
    top: 30px;
}
.feed-btn {
    cursor: pointer;
    position: relative;
    bottom: 20px;
}