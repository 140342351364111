.merch_sec {
    height: 80vh;
    background-image: url('../../assets/Img/merch-img.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.merch_btn {
    top: 30%;
    left: 46%;
    border: none;
    outline: none;
    color: #fff;
    background-color: #7031bd;
    font-size: 24px;
    padding: 8px 44px 10px 44px;
    font-weight: 500;
    border-radius: 40px;
}

.merch_btn:hover {
    background-color: #7b0083;
}

@media (max-width: 767px) {
    .merch_sec {
        height: 80vh;
        background-image: url('../../assets/Img/merch-img.png');
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
    }
    
    .merch_btn {
        top: 30%;
        left: 46%;
        border: none;
        outline: none;
        color: #fff;
        background-color: #7031bd;
        font-size: 24px;
        padding: 8px 44px 10px 44px;
        font-weight: 500;
        border-radius: 40px;
    }
}