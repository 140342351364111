

.potion_after_mint_tokenNo > h3{
    font-size: 16px;
}

.potion_after_mint_nftImg > img {
    width: 60px;
    height: 60px;
    border-radius: 4px;
}

.potion_after_mint_dropdown {
    max-width: 400px;
}