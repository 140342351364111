

.roadmap {
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding-top: 10px;
    position: relative;
}

.roadmap > img {
    width: 100%;
    height: 100%;
}

.hover_pointer {
    position: absolute;
    cursor: pointer;

}

.closet {
    width: 29% ;
    height: 18%; 
    top: 18%;
    left: 40%;
}

.lounge {
    width: 26%;
    height: 11.5%;
    top: 30%;
    left: 8%;
}

.snack_bar {
    width: 24% ;
    height: 20%; 
    top: 33%;
    right: 2%;
}

.studio {
    width: 35.5%;
    height: 27.5%;
    top: 63%;
    left: 15%;
}

.lab {
    width: 32%;
    height: 27%;
    bottom:  5%;
    right: 1%;
}