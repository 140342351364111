
.popup_wrapper {
    position: absolute;
    top: 0px;
    bottom: -3000px;
    left: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, .8);
    display: flex;
    justify-content: center;
    z-index: 1010;
}

.popup_container {
    top: calc(50% - 220px);
    position: fixed;
    width: 750px;
    height: max-content;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    z-index: 1011;
}

.close_popup {
    border: none;
    outline: none;
    width: 20px;
    height: 20px;
    display: flex;
    margin-left: auto;
    padding: 0;
    color: #444;
}

.nft_img {
    width: 50%;
    max-width: 340px;
    background: #FFFFFF;
    border: 1.38498px solid #D7D7D7;
    border-radius: 5.53991px;
    padding: 12px;
}

.nft_img_alt {
    width: 100%;
    max-width: 340px;
    background: #FFFFFF;
    border: 1.38498px solid #D7D7D7;
    border-radius: 5.53991px;
    padding: 12px;
}

.popup_clock {
    width: 100%;
    height: 80px;
    max-width: 340px;
    background: #383838;
    margin-bottom: 32px;
    border-radius: 5px;
}

.popup_time > span:nth-child(odd) {
    background-color: #FFFFFF;
    padding: 0px 8px;
    margin: 4px;
    text-align: center;
    font-size: 40px;
    color: #383838;
    font-weight: 600;
    border-radius: 5px;
    width: 2.8ch;
}

.popup_time > span:nth-child(even) {
    margin: 4px;
    text-align: center;
    font-size: 40px;
    color: #fff;
    font-weight: 600;
}

.nft_popup_btn {
    width: 100%;
    border: none;
    outline: none;
    height: 60px;
    border-radius: 8px;
    background-color: #7B0083;
    font-size: 24px;
    font-weight: 500;
    margin: 15px 0;
    color: #fff;
}

.nft_popup_btn_disabled {
    width: 100%;
    border: none;
    outline: none;
    height: 60px;
    border-radius: 8px;
    background-color: #F0F0F0;
    font-size: 24px;
    font-weight: 500;
    margin: 15px;
    color: #808080;
    cursor: not-allowed;
    border: 2px solid #7B0083;
}

.feed_popup_err {
    max-width: 300px;
}

@media (max-width: 767px) {
    
    .popup_container {
        top: calc(50% - 260px);
        max-width: 360px;
        width: 98%;
    }

    .nft_img {
        width: 70%;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    
    .popup_clock {
        margin-bottom: 20px;
        height: 60px;
        max-width: 300px;
    }

    .popup_time > span:nth-child(odd) {
        padding: 0px 8px;
        margin: 4px;
        font-size: 32px;
        width: 2.8ch;
    }
    
    .popup_time > span:nth-child(even) {
        margin: 4px;
        font-size: 32px;
    }
    
    .nft_popup_btn {
        width: 94%;
        margin: 10px;
    }

    .nft_popup_btn_disabled {
        width: 94%;
        margin: 10px;
    }
}
.captcha-code-img{
    width: 200px;
    height: 60px;
}
