

.nft_card {
    width: 19%;
    margin: 12px .5%;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
}

.nft_card > img {
    cursor: pointer;
    max-width: 100%;
}

.add_to_fav{
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.tokan_no {
    font-size: 20px;
    font-weight: 600;
}

@media (max-width: 940px) {
    .nft_card {
        width: 24%;
    }   
}

@media (max-width: 800px) {
    .nft_card {
        width: 32%;
    }   
}

@media (max-width: 600px) {
    .nft_card {
        width: 48%;
        margin: 1%;
    }   
}

@media (max-width: 420px) {
    .nft_card {
        width: 90%;
        margin: 5%;
    }   
}