.App {
  text-align: center;
  box-sizing: border-box;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

/* width */
::-webkit-scrollbar{
  width: 10px;
  height: auto;
}

/* Track */
::-webkit-scrollbar-track{
  border-radius: 10px;
}
  
/* Handle */
::-webkit-scrollbar-thumb{
  background: silver;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover{
  background: silver;
}

@-moz-document url-prefix(){
  
  /* width */
  ::-webkit-scrollbar{
    width: 10px;
    height: auto;
  }
  
  /* Track */
  ::-webkit-scrollbar-track{
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb{
    background: silver;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover{
    background: silver;
  }
}
  
  