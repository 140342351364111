
.edit_Profile {
    width: 600px;
    margin-bottom: 30px;
}


.edit_profile_img {
    width: 150px;
    height: 150px;
    background-color: rgba(1, 191, 217, .85);
    border-radius: 150px;
    top: -75px;
    left: calc(50% - 75px);
    margin-bottom: 60px;
}

.edit_profile_img > img {
    width: 100%;
    height: 100%;
    border-radius: 150px;
    opacity: .4;
}

.edit_userimg_pointer {
    width: 40px;
    height: 40px;
    top: -20px;
    left: calc(50% - 20px);
    border-radius: 4px;
    color: #999;
}

.loaderDiv {
    z-index: 1000;
    top: 100px;
    left: calc(50% - 30px);
}

.edit_userimg_pointer > label > svg {
    width: 100%;
    cursor: pointer;
}

.form_1 {
    margin-top: 105px;
}

.form_1 > input {
    height: 80px;
    border: none;
    color: #808080;
    background-color: #F0F0F0;
    border-radius: 8px;
    padding: 0 40px;
    font-size: 24px;
}

.form_1 > input:focus {
    outline: 2px solid  rgba(123, 0, 131, .5);
    outline-offset: -2px;
}

.form_2 {
    margin-top: 50px;
    background: #FFFFFF;
    border: 1.38498px solid #D7D7D7;
    border-radius: 5.53991px;
    padding: 24px 20px;
}

.form_2 > span {
    color: #383838;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
}

.form_2 > input {
    height: 74px;
    border: none;
    color: #808080;
    background-color: #F0F0F0;
    border-radius: 8px;
    padding: 0 30px;
    font-size: 24px;
    margin: 20px 0px ;
}

.form_2 > input:focus {
    outline: 2px solid  rgba(123, 0, 131, .5);
    outline-offset: -2px;
}

.form_3 {
    margin-top: 50px;
    background: #FFFFFF;
    border: 1.38498px solid #D7D7D7;
    border-radius: 5.53991px;
    padding: 24px 20px;
}

.form_3 > header {
    color: #383838;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
}

.form_3 > input:focus {
    outline: 2px solid  rgba(123, 0, 131, .5);
    outline-offset: -2px;
}

.form_3 > div > input {
    width: 100%;
    height: 74px;
    border: none;
    color: #808080;
    background-color: #F0F0F0;
    border-radius: 8px;
    padding: 0 70px 0 30px;
    font-size: 24px;
    margin-top: 40px;
}

.form_3 > div > input:focus {
    outline: 2px solid  rgba(123, 0, 131, .5);
    outline-offset: -2px;
}

.border_warn {
    outline: 2px solid  #d9534f !important;
}

.add_btn {
    color: #7B0083;
    font-size: 20px;
    font-weight: 400;
    margin-top: 30px;
    cursor: pointer;
}

.TWITTER, .INSTAGRAM {
    width: 40px;
    height: 40px;
    padding: 6px;
    right: 40px;
}

.TWITTER > img, .INSTAGRAM > img {
    width: 100%;
}

.TWITTER {
    top: 200px;
}

.INSTAGRAM {
    top: 85.5px;
}

.first_eth_link_icon {
    width: 40px;
    height: 40px;
    padding: 6px;
    right: 24px;
    top: 42px;
    cursor: pointer;
}

.eth_link_icon {
    width: 40px;
    height: 40px;
    padding: 6px;
    right: 24px;
    top: 56px;
    cursor: pointer;
}

.profile_upload, [type='file'] {
    position: absolute;
    width: 40px;
    min-height: 40px;
    top: 5px;
    left: calc(50% - 20px);
    cursor: pointer;
    z-index: 100;
    opacity: 0;
}

.edit_profile_label {
    margin-top: 28px;
    font-size: 18px;
    color: rgb(1, 191, 217);
    margin-right: auto;
    margin-left: 12px;
}

::-webkit-file-upload-button {
    cursor:pointer;
}

@media (max-width: 660px) {
    .edit_Profile {
        width: 400px;
        margin-bottom: 16px;
    }
    .form_1 > input {
        height: 60px;
        padding: 0 32px;
        font-size: 20px;
    }
    .form_2 > input {
        height: 56px;
        padding: 0 16px;
        font-size: 20px;
        margin: 20px 0px ;
    }
    .TWITTER, .INSTAGRAM {
        right: 32px;
    }
    .TWITTER {
        top: 171px;
    }
    .INSTAGRAM {
        top: 74px;
    }
    .form_3 > div > input {
        height: 56px;
        padding: 0 46px 0 16px;
        font-size: 20px;
        margin-top: 32px;
    }
    .first_eth_link_icon {
        right: 8px;
        top: 34px;
    }
    .eth_link_icon {
        right: 8px;
        top: 40px;
    }
}

@media (max-width: 420px) {
    .edit_Profile {
        width: 355px;
        margin-bottom: 10px;
    }
}