@font-face {
    font-family: 'Bebas';
    src: url('../../assets/fonts/Bebas-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Bebas-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.banner_sec {
    background-color: #7031bd;
}

.banner_img > img {
    width: 100%;
    margin-top: auto;
}

.banner_content {
    overflow: hidden;
    text-align: left;
    padding: 20px;
    color: #fff;
    padding-right: 60px;
}

.banner_content > h3 {
    font-family: 'Bebas';
}

.banner_content > img {
    max-width: 100%;
}

.banner_content > p {
    font-size: 23px;
    padding-right: 70px;
}

.banner_content > p {
    font-weight: 600;
    letter-spacing: 1px;
}

.banner_btn {
    padding: 10px 40px;
    border-radius: 40px;
    background-color: #000;
    color: #fff;
    border: none;
    outline: none;
    border: 2px solid #000;
    font-weight: 600;
    font-size: 18px;
}

.banner_btn:hover {
    border: 2px solid #fff;
    background-color: #7031bd;
    outline: none;
}

@media (max-width: 768px) {
    .banner_content > p {
        font-size: 18px;
        padding-right: 0px;
    }

    .banner_content {
        padding: 20px;
    }
}