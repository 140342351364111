@font-face {
    font-family: 'Bebas';
    src: url('../../assets/fonts/Bebas-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Bebas-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.signup_wrapper {
    width: 100%;
    min-height: 72.5vh;
    background-color: rgba(0, 0, 0, .75);
    background-image: url("../../../public/img/bg-3.jpg");
    background-size: contain;
    background-repeat: repeat;
    overflow-y: hidden;
}

.signup_card {
    width: 750px;
    background-color: #333333;
    border-radius: 8px;
}

.signup_title {
    font-size: 90px;
    /* font-weight: 300; */
    color: #FFF;
    font-family: 'Bebas';
}

.signup_text {
    font-family: Arial;
    font-size: 20px;
    color: #FFF;
}

.web3-modal-wrapper {
    z-index: 10000;
}

.signup_wrapper > img {
    width:  100%;
    max-width: 1170px;
    height: 100%;
    /* display: none; */
}

.signup_card > img {
    max-height: 80px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.signup_top_gradint {
    width: 100%;
    height: 80px;
    background-color: #009a88;
    /* background-color: rgba(123, 0, 131, .75); */
    top: 0;
    left: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.signup_card > input {
    width: 86%;
    height: 54px;
    margin: 0 auto;
    border: none;
    color: #808080;
    background-color: #e0e0e0;
    border-radius: 8px;
    padding: 0 170px 0 20px;
    font-size: 24px;
}

.signup_card > input:focus {
    outline: 2px solid  rgba(123, 0, 131, .5);
    outline-offset: -2px;
}

.login_input {
    width: 86%;
    height: 54px;
    margin: 0 auto;
    border: none;
    color: #808080;
    background-color: #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    font-size: 24px;
}

.password_input {
    padding: 0 60px 0 20px;
}

.login_input > input:focus {
    outline: 2px solid  rgba(123, 0, 131, .5);
    outline-offset: -2px;
}

.signup_card > button {
    display: inline-block;
    width: 86%;
    height: 54px;
    margin: 0 auto;
    border-radius: 8px;
    font-size: 26px;
    font-weight: 600;
    background: #7B0083;
    border: 2px solid #7B0083;
    color: #fff;
    outline: 2px solid #fff;
    transition: outline-offset 250m s ease;
}


.signup_card > button:hover, 
.signup_card > button:focus {
    outline: 2px solid #fff;
    outline-offset: -4px;
}

.reconnect_to_mm {
    right: 56px;
    font-size: 20px;
    border-left: 2px solid rgba(123, 0, 131, .5);
    margin: 7px;
    cursor: pointer;
    position: relative;
    color: #7031BD;
    font-weight: 500;
}

.reconnect_to_mm > div > img {
    width: 44px;
}

.reconnect_to_mm::before,
.reconnect_to_mm::after {
    --scale: 0;
    position: absolute;
    bottom: -1rem;
    left: 50%;
    transform: translateX(-10%) translateY(100%) scale(var(--scale));
    transform: 50ms trasform;
    transform-origin: top center;
}

.reconnect_to_mm::before {
    content: attr(data-tooltip);
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    width: max-content;
    background-color: #fff;
    font-size: 14px;
    outline: 1px solid #7031BD;
    outline-offset: -2px;
    background-color: #fff;
    color: #7031BD;
}

.reconnect_to_mm:hover::after,
.reconnect_to_mm:hover::before {
    --scale: 1;
}

.login_link {
    margin-bottom: 24px;
    text-decoration: underline;
}

.login_link > a {
    color: #5ac8fa;
}

.login_link > a:hover {
    color: #7031BD;
}

.toggel_pass {
    right: 50px;
    font-size: 20px;
    border-left: 2px solid rgba(123, 0, 131, .5);
    margin: 10px;
    margin-top: 26px;
    cursor: pointer;
    position: relative;
    color: #7031BD;
    font-weight: 500;
}

.required_warn_login {
    margin-right: 40px;
}

.toggel_pass > svg { 
    width: 32px;
    margin-left: 10px;
}

@media ( max-width: 576px ) {
    .signup_card {
        width: 360px;
    }
    
    .signup_title {
        font-size: 40px;
    }
    
    .signup_top_gradint {
        height: 48px;
    }

    .signup_card > input { 
        font-size: 18px;
    }

    .signup_card > button { 
        font-size: 18px;
    }
}