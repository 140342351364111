
.pick_your_pet_container {
    background-color: #fff; 
    padding: 20px 40px 200px 40px; 
    z-index: 90;
}

.pick_your_pet_container2 {
    background-color: #fff; 
    padding: 20px 40px;
}

.pet_card_alt {
    width: 23%; 
    margin: 1%;
}

.pet_card_alt > img {
    width: 100%;
}

.pick_your_pet_container > div > div {
    margin-bottom: 0;
}

.pick_your_pet_container2 > div > div {
    margin-bottom: 0;
}


@media (max-width: 768px) {
    .pet_card {width: 23%; margin: 1%;}
}
@media (max-width: 420px) {
    .pick_your_pet_container {background-color: #fff; padding: 0px 40px 120px 40px; }
    .pet_card_alt {width: 47%; margin: 1.5%;}
    .pick_your_pet_container2 {padding-top: 0;}
 }