.btn-wallet-connect {
    border: none;
    outline: none;
    background-color: #07bc0c;
    color: #333;
    font-size: 26px;
    font-weight: 600;
    border-radius: 6px;
}

/* // connect modal css */

.connect-wallet-modal-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    z-index: 2;
}

.connect-wallet-modal {
    width: 380px;
    background-color: white;
    border-radius: 6px;
}

.btn-cwm > img {
    max-width: 24px ;
    height: 24px ;
}
