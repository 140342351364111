@font-face {
    font-family: 'ChameleonCollective';
    src: url('../../assets/fonts/ChameleonCollective-Regular.otf'),
        url('../../assets/fonts/ChameleonCollective-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.compare-wishlist-page {
    min-height: 85vh;
}

.compare-wishlist-page-heading {
    font-family: 'ChameleonCollective';
}

.compare-wishlist-check-box {
    top: 0;
    left: 8px;
    z-index: 200;
}

.wishlist-add-to-cart-modal-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    z-index: 1000;
}

.Remove-from-wishlist-icon {
    top: -18px;
    right: -18px;
    z-index: 100;
}

.Remove-from-wishlist-icon .tooltiptext {
    visibility: hidden;
    width: 60px;
    background-color: #fff;
    color: red;
    border: 2px solid red;
    text-align: center;
    font-size: 12px;
    border-radius: 4px;
    padding: 4px 0;
    
    /* Position the tooltip */
    position: absolute;
    right: 36px;
    top: 5px;
    z-index: 101;
}
    
.Remove-from-wishlist-icon:hover .tooltiptext {
    visibility: visible;
}
      
.wl-img-checkout {
    width: 60px;
    border: 1px solid #a6a6a6;
    border-radius: 4px;
}