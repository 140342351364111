
p {
    margin: 0;
}

.text_content_1 {
    font-size: 26px; 
    color: #555555; 
    padding: 40px;
    text-align: left;
}

.text_content_1 > span {
    font-size: 32px; 
    font-family: 'Bebas'; 
    font-weight: 400;
}

.text_content_1 > P {
    font-size: 23PX;
}

.intro_text_container {
    width: 60%; 
}

.intro_img {
    width: 40%; 
}

.intro_img > img {
    width: 100%;
}

.coloredTitle {
    padding: 2px 40px;
    margin-left: 20px; 
    color: #fff; 
    font-size: 26px; 
    font-family: 'Bebas';
    margin-right: auto;
}  

.pink_bg {
    background-color: #e090b9;
}

.green_bg {
    background-color: #88c183;
}

.purple_bg {
    background-color: #a48ac7;
}

.blue_bg {
    background-color: #7595c6;
}

.text_content_2 {
    font-size: 22px; 
    color: #555555; 
    padding: 0px 10px 40px 120px ;
}

.text_content_3 {
    font-size: 22px; 
    color: #555555; 
    padding: 0px 10px 40px 55px ;
}

.text_content_gole {
    font-size: 22px; 
    color: #555555; 
    padding: 32px;
}

.money_icon {
    height: 80%; 
    padding: 0 20px;
}

.money_icon2 {
    height: 80%; 
    padding: 0 10px;
}

.money_gole {
    width: 60px; 
    height: 100%; 
    padding: 10px
}