.dressing-preview-section-cham {
  z-index: 1;
}

.dressing-preview-section-overlay {
  top: 0;
  left: 0;
  z-index: 10;
}

.add-to-wishlist-icon {
  top: 1px;
  right: 8px;
  z-index: 100;
}

.add-to-wishlist-icon .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: black;
  color: #fff;
  text-align: center;
  font-size: 12px;
  border-radius: 4px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  right: 24px;
  z-index: 101;
}

.add-to-wishlist-icon:hover .tooltiptext {
  visibility: visible;
}

.dressing-cham-preview-layered-bg{
  z-index: -1;
}

.dressing-cham-preview-layered {
  top: 0;
}

.dressing-cham-Overlay {
  z-index: 50;
}

.dressing-cham-Background {
  z-index: 10;
}

.dressing-cham-Eye {
  z-index: 35;
}

.dressing-cham-Mouth {
  z-index: 30;
}

.dressing-cham-Clothes {
  z-index: 40;
}

.dressing-cham-Hat {
  z-index: 20;
}

.dressing-cham-Body {
  z-index: 25;
}

.dressing-cham-Prop {
  z-index: 45;
}

.dressing-cham-Tail {
  z-index: 15;
}

.dressing-cham-Robotic {
  z-index: 55;
}

.dressing-cham-Pygmy {
  z-index: 55;
}

.dressing-cham-Mutant {
  z-index: 55;
}

.dressing-cham-Holograph {
  z-index: 60;
}

.dressing-cham-Noir {
  z-index: 60;
}

.dressing-cham-Cosmic {
  z-index: 60;
}

.dressing-cham-Tasty_Cham {
  z-index: 65;
}

.dressing-cham-3D {
  z-index: 65;
}

.dressing-cham-Ash_Cham {
  z-index: 65;
}

.dressing-cham-Doodle_Cham {
  z-index: 65;
}

.dressing-cham-Invisible_Cham {
  z-index: 65;
}

.dressing-cham-Raindrops {
  z-index: 65;
}

.dressing-cham-Treasure_Chest_25_E {
  z-index: 65;
}

.dressing-cham-Treasure_Chest_5_E {
  z-index: 65;
}

.dressing-cham-Special {
  z-index: 70;
}

.dressing-cham-Border {
  z-index: 70;
}

.dressing-cham-Weapon_Arm {
  z-index: 16;
}

.dressing-cham-Weapon {
  z-index: 17;
}

.dressing-cham-Armor {
  z-index: 41;
}

.dressing-cham-Helmet {
  z-index: 21;
}

.dressing-cham-Mask {
  z-index: 46;
}