@font-face {
    font-family: 'ChameleonCollective';
    src: url('../../assets/fonts/ChameleonCollective-Regular.otf'),
        url('../../assets/fonts/ChameleonCollective-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas';
    src: url('../../assets/fonts/Bebas-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Bebas-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.faq_heading {
    font-family: 'ChameleonCollective';
    color: #e486a1;
    font-size: 40px;
}

.faq_que {
    font-family: 'Bebas';
    font-size: 24px;
    letter-spacing: .5px;
    word-spacing: 1px;
}

.faq_ans {
    font-size: 18px;
    word-wrap: no;
}

.faq_ans_img {
    width: 50%;
}

.faq_link {
    color: blue;
}

.faq_link:hover {
    color: #01BFD9;
}